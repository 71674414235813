import bgMap from '../../assets/images/map/bg_map_1.png';

import hotspot6Thumbnail from '../../assets/images/project/山海世界/山海世界.jpg';
import hotspot6ThumbnailHidden from '../../assets/images/project/山海世界/山海世界_white.jpg';

import hotspot5Thumbnail from '../../assets/images/project/社区聚集地/社区聚集地.jpg';
import hotspot5ThumbnailHidden from '../../assets/images/project/社区聚集地/社区聚集地_white.jpg';

import hotspot4Thumbnail from '../../assets/images/project/Metabell总部/Metabell总部.jpg';
import hotspot4ThumbnailHidden from '../../assets/images/project/Metabell总部/Metabell总部_white.jpg';

import hotspot3Thumbnail from '../../assets/images/project/大都会/大都会.jpg';
import hotspot3ThumbnailHidden from '../../assets/images/project/大都会/大都会_white.jpg';

import hotspot2Thumbnail from '../../assets/images/project/运动场/运动场.jpg';
import hotspot2ThumbnailHidden from '../../assets/images/project/运动场/运动场_white.jpg';

import hotspot1Thumbnail from '../../assets/images/project/赛马会/赛马会.jpg';
import hotspot1ThumbnailHidden from '../../assets/images/project/赛马会/赛马会_white.jpg';
import { MapLocation, MapLocationHotspot, ShareHotspot } from '../../types/nyMapTypes';


const shareHotspot: ShareHotspot = {
    markerPositionDesktop: {
        x: '60%',
        y: '20%',
    },
    markerPositionMobile: {
        x: '70%',
        y: '26%',
    },
    type: 'share',
    isPostcard: true,
};

const hotspot1: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '40.25%',
        y: '60%',
    },
    markerPositionMobile: {
        x: '26%',
        y: '56%',
    },
    type: 'DEVELOPED',
    title: `<span>赛马会</span>`,
    cleanTitle: '赛马会',
    description: `无论你是一个经验丰富的赛马迷或一个好奇的新人，赛马会提供给大家新鲜的体验。元宇宙赛马会是一个基于区块链技术和智能合约的数字赛马游戏。用户可以在其中购买、训练和比赛数字赛马，通过比赛来获得奖励和提升赛马的排名。赛马会的游戏规则和赛制都是公正、透明、安全和有趣的，让用户可以在其中尽情地享受数字赛马的乐趣。同时，赛马会也为数字藏家和投资者提供了一个新的投资和收藏领域。`,
    thumbnail: {
        src: hotspot1ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot1ThumbnailHidden,
                alt: 'WISHING FOUNTAIN',
            },
        ],
        hiddenContent: {
            src: hotspot1Thumbnail,
            alt: 'WISHING FOUNTAIN',
        },
        isVideo: false,
    },
};

const hotspot2: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '60%',
        y: '65%',
    },
    markerPositionMobile: {
        x: '40%',
        y: '49%',
    },
    type: 'DEVELOPED',
    title: '<span>运动场</span>',
    cleanTitle: '运动场',
    description: `这里提供了各种虚拟运动项目和比赛，让用户可以在其中尽情地体验各种运动的乐趣。运动场中的项目和比赛包括但不限于足球、赛车、极限运动等。用户可以自由选择自己喜欢的项目和比赛，通过实时互动和PK来展现自己的实力和技巧。元宇宙运动场的开放性和自由度使得用户可以在其中享受到与现实世界相似的真实感和刺激感。`,
    thumbnail: {
        src: hotspot2ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot2ThumbnailHidden,
                alt: '',
            },
        ],
        hiddenContent: {
            src: hotspot2Thumbnail,
            alt: '',
        },
        isVideo: false,
    },
    closeButtonBlack: false,
    url: 'https://space.metafactoryhub.io/#/world/gospace',
};

const hotspot3: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '80%',
        y: '55%',
    },
    markerPositionMobile: {
        x: '61%',
        y: '55%',
    },
    type: 'DEVELOPING',
    title: '<span>NFT BATTLE</span>',
    cleanTitle: 'HERA SPORTS CENTER',
    description: `这里是PHK NFT BATTLE。NFT BATTLE是一个基于区块链和NFT技术的元宇宙场景，它提供了一个开放的社区平台，供玩家在其中进行PK游戏和交互活动。在NFT BATTLE中，你可以创建自己的数字身份和虚拟资产，并将其用于进行PK游戏或交易。而这些虚拟资产则是通过NFT技术来实现其唯一性和不可替代性的。NFT社区在NFT BATTLE元宇宙场景内可以参加刺激的PK大乱斗。在这场比赛中，你可以使用自己拥有的NFT虚拟资产来进行游戏。这些资产可以是武器、防具、装备等各种类型，每个资产都有自己独特的属性和特点。你可以根据自己的战术和技能来选择和搭配虚拟资产，制定出最佳的战斗方案。在PK大乱斗中，你需要展现出自己的实力和技巧，通过击败其他玩家来获得胜利和奖励。大都会元宇宙场景的开放性和自由度使得PK大乱斗更加多样化和有趣，你都可以找到自己喜欢的游戏模式和挑战。大都会的目标是为你提供一个公正、透明、安全和有趣的元宇宙环境，希望你在其中尽情地享受数字世界的乐趣。`,
    thumbnail: {
        src: hotspot3ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot3ThumbnailHidden,
                alt: '',
            },
        ],
        hiddenContent: {
            src: hotspot3Thumbnail,
            alt: '',
        },
        isVideo: false,
    },
};
const hotspot4: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '62%',
        y: '45%',
    },
    markerPositionMobile: {
        x: '85%',
        y: '44%',
    },
    type: 'DEVELOPED',
    title: '<span>MineFI</span>',
    cleanTitle: 'MineFI',
    description: `MineFI矿区是元宇宙中最具活力和潜力的数字资产矿区之一。在MineFI矿区，用户可以通过挖掘、交易和投资等多种方式获取MineFI
数字资产，并且可以将MineFI数字资产用于购买元宇宙中的虚拟商品和服务。同时，MineFI矿区也为数字资产开发者和投资者提供了一个全新的开发和投资领域，让他们可以在其中尽情发挥创造力和投资智慧。我们很高兴有你和我们在一起，并希望你有一个很棒的体验。`,
    thumbnail: {
        src: hotspot4ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot4ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot4Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
    url: 'https://space.metafactoryhub.io/#/world/minefi',
};


// const hotspot4: MapLocationHotspot = {
//     markerPositionDesktop: {
//         x: '62%',
//         y: '45%',
//     },
//     markerPositionMobile: {
//         x: '88%',
//         y: '64%',
//     },
//     type: 'PLANNING',
//     title: '<span>Metabell总部</span>',
//     cleanTitle: 'Metabell总部',
//     description: `欢迎来到Metabell总部，Metabell总部是Metaverse的创新和创造力中心，致力于开发基于区块链技术的去中心化应用程序和服务。
// 在Metabell，我们的使命是创造一个人们可以连接、协作和共同构建惊人事物的世界我们的总部是这一愿景的心脏，在这里你可以遇到Metaverse探索者，参加前沿活动，并沉浸在区块链技术和虚拟现实的最新发展中。
// 无论你是来参加研讨会，加入我们的孵化器项目，还是只是探索Metaverse令人难以置信的可能性，我们都在这里支持你的每一步。我们的专家团队热衷于创建一个包容、支持、始终走在创新前沿的社区。
// 所以进来吧，看看周围，加入我们这个不可思议的Metaverse之旅。我们迫不及待地想看到你在这个神奇的新世界里会取得什么样的成就。`,
//     thumbnail: {
//         src: hotspot4ThumbnailHidden,
//         alt: 'alt',
//         positionX: 'left',
//         positionY: 'top',
//     },
//     mediaContent: {
//         visibleContent: [
//             {
//                 src: hotspot4ThumbnailHidden,
//                 alt: '',
//             },
//         ],
//         hiddenContent: {
//             src: hotspot4Thumbnail,
//             alt: '',
//         },
//         isVideo: false,
//     },
// };

const hotspot5: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '88%',
        y: '40%',
    },
    markerPositionMobile: {
        x: '50%',
        y: '70%',
    },
    type: 'PLANNING',
    title: '<span>社区聚集地</span>',
    cleanTitle: '社区聚集地',
    description: `欢迎来到PHK元宇宙社区聚集地！这里是一个基于区块链技术的虚拟社区，旨在为PHK社区成员提供一个安全、开放、互动的交流平台。在这里，您可以通过身临其境的虚拟现实环境与其他成员交流、分享、学习和创作。
我们的社区聚集地拥有丰富多彩的内容和活动，包括艺术展览、音乐会、游戏比赛、技术交流、社交活动等。同时，我们也提供了多种社交工具和服务，方便成员之间的交流和合作，包括即时聊天、语音通话、在线协作、数字资产交易等。
、多样性和创新性的社交网络，让每一个成员都能够找到自己的归属感和价值所在。加入我们，探索元宇宙的无限可能！`,
    thumbnail: {
        src: hotspot5ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'top',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot5ThumbnailHidden,
                alt: '',
            },
        ],
        hiddenContent: {
            src: hotspot5Thumbnail,
            alt: '',
        },
        isVideo: false,
    },

};


const hotspot6: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '30%',
        y: '42%',
    },
    markerPositionMobile: {
        x: '50%',
        y: '70%',
    },
    type: 'PLANNING',
    title: '<span>山海世界</span>',
    cleanTitle: '山海世界',
    description: `你想象过在山海经所描绘的世界中遨游吗？这个梦想现在可以在游戏《山海世界》中实现啦。《山海世界》这款基于区块链技术开发的多人在线角色扮演游戏，游戏以中国传统文化中的山海经为背景，打造出一个神秘的神话世界。游戏中的道具、装备、角色等各种游戏资产都以非同质化代币（NFT）的形式存储于区块链上，确保游戏资产的去中心化和不可篡改性。构建跨链解决方案，提高玩家游戏资产交易效率，降低交易手续费，让玩家在不同区块链网络之间自由切换。 打造链游版Steam，吸引圈外 & 非区块链用户，降低传统游戏玩家参与门槛。 实现法币和代币在加密行业合规国家和地区的自由流通，打造全链属性、Web2/Web3高度互通、游戏与金融完美融合的游戏专属平台链。快进来一起体验吧！`,
    thumbnail: {
        src: hotspot6ThumbnailHidden,
        alt: 'alt',
        positionX: 'right',
        positionY: 'top',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot6ThumbnailHidden,
                alt: '',
            },
        ],
        hiddenContent: {
            src: hotspot6Thumbnail,
            alt: '',
        },
        isVideo: false,
    },

};


const downtownLocationData: MapLocation = {
    bg: bgMap,
    bgMobile: '',
    path: '',
    name: '应用',
    cleanName: 'Downtown',
    shortName: '应用',
    shortNameClass: 'Downtown',
    description: `这里汇集着优秀的web3应用，NFT社区在此扎根，他们像血气方刚的战士，在web3的领域驰骋疆土`,
    backgroundImagePath: '../',
    hotspots: {
        mobile: [hotspot1, hotspot2, hotspot3, hotspot4, hotspot5, /*hotspot6*/],
        desktop: [hotspot1, hotspot2, hotspot3, hotspot4, hotspot5, /*hotspot6*/],
    },
    shareHotspot,
};

export { downtownLocationData };
