import { downtownLocationData } from './downtownLocationData';
import { midtownLocationData } from './midtownLocationData';
import { uptownLocationData } from './uptownLocationData';

const nyData: Array<any> = [
  downtownLocationData,
  midtownLocationData,
  uptownLocationData,
];

export { nyData };
