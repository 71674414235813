import './CustomCursor.scss';

import classNames from 'classnames';
import React, { useCallback, useEffect, useRef,useState } from 'react';

import { useDeviceState } from '../../hooks/useDeviceState';

export enum CustomCursorState {
  Default = 'default',
  Pointer = 'pointer',
  Action = 'action',
  ActionBig = 'actionBig',
  Mask = 'mask',
  MaskAlt = 'maskAlt',
}

export enum CustomCursorTheme {
  Primary = '',
  Secondary = 'secondary',
}

const CustomCursor: React.FC = () => {
  const { isTouch } = useDeviceState();
  const [cursorState, setCursorState] = useState(CustomCursorState.Default);
  const [cursorTheme, setCursorTheme] = useState(CustomCursorTheme.Secondary);
  const [cursorText, setCursorText] = useState('');
  const [showText, setTextVisibility] = useState(false);
  const cursorElement = useRef<HTMLDivElement>(null);
  const cursorPosition = useRef<{ x: number; y: number }>({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });

  const previousTargetElement = useRef<HTMLElement | null>(null);
  const previousState = useRef<CustomCursorState | null>(cursorState);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTouch) {
        return;
      }

      const targetElement = document.elementFromPoint(
        cursorPosition.current.x,
        cursorPosition.current.y,
      ) as HTMLElement;

      if (previousTargetElement.current !== targetElement) {
        if (
          previousState.current === CustomCursorState.Mask ||
          previousState.current === CustomCursorState.MaskAlt
        ) {
          // horrible workaround for Chrome issue with onMouseLeave event not triggering when scrolling
          if (previousTargetElement.current) {
            previousTargetElement.current.style.opacity = '0';
          }
        }
        previousTargetElement.current = targetElement;
      }

      cursorElement.current!.style.left = `${cursorPosition.current.x}px`;
      cursorElement.current!.style.top = `${cursorPosition.current.y}px`;

      if (!targetElement) {
        return;
      }

      const targetElementCustomCursorText = targetElement.getAttribute('data-custom-cursor-text');
      const targetelementCustomCursorState = targetElement.getAttribute(
        'data-custom-cursor-state',
      ) as CustomCursorState;
      previousState.current = targetelementCustomCursorState;
      const menuIsOpen = !!document.getElementById('navigationMenu');

      if (
        targetelementCustomCursorState === CustomCursorState.Mask ||
        targetelementCustomCursorState === CustomCursorState.MaskAlt
      ) {
        const circleXPos = cursorPosition.current.x - targetElement.getBoundingClientRect().x;
        const circleYPos = cursorPosition.current.y - targetElement.getBoundingClientRect().y;

        const targetChild = targetElement.children[0]! as HTMLElement;

        if (targetChild) {
          targetChild.style.clipPath = `circle(${
            cursorElement.current!.clientWidth / 2
          }px at ${circleXPos}px ${circleYPos}px)`;
        }

        targetElement.style.opacity = '1';
      }

      setCursorState(targetelementCustomCursorState || CustomCursorState.Default);
      setCursorTheme(menuIsOpen ? CustomCursorTheme.Secondary : CustomCursorTheme.Primary);
      setCursorText(targetElementCustomCursorText || '');
      setTextVisibility(!!targetElementCustomCursorText);
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [isTouch]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!isTouch) {
        cursorPosition.current = { x: event.clientX, y: event.clientY };
      }
    },
    [isTouch],
  );

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove]);

  return !isTouch ? (
    <div ref={cursorElement} className={classNames('customCursor', cursorState, cursorTheme)}>
      <div className={'background'}/>
      <span
        className={classNames('text', showText && 'showText', 'globalButton01')}
        dangerouslySetInnerHTML={{ __html: cursorText }}
      />
    </div>
  ) : null;
};

export { CustomCursor };
