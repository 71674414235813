export const DeviceState = {
  MAX_479: 0,
  MIN_480: 1,
  MIN_768: 2,
  MIN_1024: 3,
  MIN_1240: 4,
  MIN_1600: 5,
  MIN_1920: 6,
};

export const TYPE_NAME_MAP:any = {
  city: 'DEVELOPED',
  show: 'DEVELOPING',
  story: 'PLANNING'
};
