import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { BackgroundLines } from '../components/BackgroundLines/BackgroundLines';
import { NYMapView } from '../components/views/NYMap/NYMapView';
import { rootPath, RoutePaths } from '../data/enum/RoutePaths';


export const Routes: React.FC = () => {
    const canvasStyle: React.CSSProperties = {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 5,
        pointerEvents: 'none',
    };
    
    return (
        <Router basename={rootPath}>
            <BackgroundLines />
            <div id='transitionCanvas' style={canvasStyle} />
            <Switch>
                <Route path={RoutePaths.METAVERSEMIX}>
                    <NYMapView />
                </Route>
                {/* last route */}
                <Redirect path="/" to={{pathname: '/metaversemix'}} />
            </Switch>
        </Router>
    );
};
