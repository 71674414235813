import gsap from 'gsap';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MapLocation } from '../../../types/nyMapTypes';
import {
    withLocationTransition,
    WithLocationTransitionProps,
} from '../../hoc/withLocationTransition';
import { HotspotMarker } from './HotspotMarker/HotspotMarker';
import { HotspotModal } from './HotspotModal/HotspotModal';
import { HotspotPopup } from './HotspotPopup/HotspotPopup';
import styles from './NYMapDesktop.module.scss';

export interface NYMapProps extends WithLocationTransitionProps {
    location: MapLocation;
    isTransitioning: boolean;
    showPostcardMaker: boolean;
    onPostcardMakerVisibilityToggle: (newState: boolean) => void;
    showModal: boolean;
    onModalOpen: () => void;
    onModalClose: (path?: string) => void;
}

const NYMap: React.FC<NYMapProps> = withLocationTransition(
    ({
         location,
         isTransitioning,
         showPostcardMaker,
         showModal,
         onModalOpen,
         onModalClose,
     }) => {
        const mapRef = useRef<HTMLImageElement>(null);
        const [currentHotspotIndex, setCurrentHotspotIndex] = useState(0);
        const [showHotspotPopup, setHotspotPopupVisibility] = useState(false);
        const [currentHotspot, setCurrentHotspot] = useState(
            location.hotspots.desktop[currentHotspotIndex],
        );

        const { state } = useLocation<{ noTransition: boolean }>();
        const locationState = useRef(state);

        const nextHotspot = useCallback(() => {
            const totalHotspotsCount = location.hotspots.desktop.length;
            setCurrentHotspotIndex((currentHotspotIndex + 1) % totalHotspotsCount);
        }, [currentHotspotIndex, location.hotspots.desktop.length]);

        const handleOpenLink = () => {
            const hotspot = location.hotspots.desktop[currentHotspotIndex];
            if (hotspot?.url) {
                window.open(hotspot.url);
            }
        };

        const previousHotspot = useCallback(() => {
            const totalHotspotsCount = location.hotspots.desktop.length;
            setCurrentHotspotIndex((currentHotspotIndex + totalHotspotsCount - 1) % totalHotspotsCount);
        }, [currentHotspotIndex, location.hotspots.desktop.length]);

        const mouseEnter = (index: number) => {
            setCurrentHotspotIndex(index);
        };

        const onCompleteHandler = useCallback(() => {
            setHotspotPopupVisibility(true);
        }, []);

        useEffect(() => {
            setCurrentHotspot(location.hotspots.desktop[currentHotspotIndex]);
        }, [currentHotspotIndex, location.hotspots.desktop]);

        useEffect(() => {
            if (!mapRef.current || locationState.current?.noTransition) return;

            gsap.fromTo(
                mapRef.current,
                {
                    scale: 1.05,
                    translateY: -10,
                    rotateX: '3deg',
                },
                {
                    scale: 1,
                    translateY: 0,
                    rotateX: 0,
                    duration: 1,
                    delay: 0.5,
                },
            );
        }, [location]);

        return (
            <>
                <div className={styles.mapMain}>
                    <div className={styles.hotspots}>
                        {location.hotspots.desktop.map((hotspotData: MapLocation, index: number) => {
                            return (
                                <HotspotMarker
                                    key={index}
                                    hotspotData={hotspotData}
                                    onMouseEnter={() => mouseEnter(index)}
                                    onClick={onModalOpen}
                                    delayEntry={index * 0.1}
                                    onComplete={onCompleteHandler}
                                />
                            );
                        })}
                        {/*<HotspotMarker*/}
                        {/*  hotspotData={location.shareHotspot}*/}
                        {/*  onClick={() => onPostcardMakerVisibilityToggle(true)}*/}
                        {/*  delayEntry={0.1}*/}
                        {/*/>*/}
                    </div>

                    <img ref={mapRef} alt={location.name} className={styles.bgMap} src={location.bg} />
                </div>

                {/* <HotspotModal
          currentLocation={location.cleanName}
          isVisible={showHotspotModal && !isTransitioning}
          currentHotspotCopy={currentHotspotIndex + 1}
          totalHotspotsCopy={location.hotspots.desktop.length}
          hotspotData={location.hotspots.desktop[currentHotspotIndex]}
          previousHotspotClick={previousHotspot}
          nextHotspotClick={nextHotspot}
          closeClick={() => setHotspotModalVisibility(false)}
        /> */}

                <HotspotPopup
                    title={currentHotspot.title}
                    titlePreview={currentHotspot.titlePreview}
                    thumbnailProps={currentHotspot.thumbnail}
                    onButtonClick={onModalOpen}
                    left={currentHotspot.markerPositionDesktop.x}
                    top={currentHotspot.markerPositionDesktop.y}
                    isVisible={showHotspotPopup && !isTransitioning && !showModal && !showPostcardMaker}
                />

                <HotspotModal
                    currentLocation={location.cleanName}
                    isVisible={showModal && !isTransitioning}
                    currentHotspotCopy={currentHotspotIndex + 1}
                    totalHotspotsCopy={location.hotspots.desktop.length}
                    hotspotData={location.hotspots.desktop[currentHotspotIndex]}
                    previousHotspotClick={previousHotspot}
                    nextHotspotClick={nextHotspot}
                    closeClick={onModalClose}
                    openLinkClick={handleOpenLink}
                />
            </>
        );
    },
);

export { NYMap };
