import classNames from 'classnames';
import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';

// import { MapLocationHotspot, ShareHotspot } from '../../../../types/nyMapTypes';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';
import styles from './HotspotMarkerDesktop.module.scss';

interface HotspotMarkerProps {
  // hotspotData: MapLocationHotspot | ShareHotspot;
  hotspotData:any;
  delayEntry: number;
  onClick: () => void;
  onMouseEnter?: () => void;
  onComplete?: () => void;
}

const HotspotMarker: React.FC<HotspotMarkerProps> = ({
  hotspotData,
  delayEntry,
  onMouseEnter,
  onClick,
  onComplete,
}) => {
  const markerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!markerRef.current) return;

    gsap.fromTo(
      markerRef.current,
      {
        opacity: 0,
        y: -20,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        delay: delayEntry + 0.8,
        onComplete,
      },
    );
  }, [onComplete, delayEntry]);

  return (
    <div
      className={classNames(
        styles.hotspotMarker,
        hotspotData.isDarkPin && styles.darkPin,
        hotspotData.isPostcard && styles.postcardPin,
      )}
      style={{
        left: hotspotData.markerPositionDesktop.x,
        top: hotspotData.markerPositionDesktop.y,
      }}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      ref={markerRef}
    >
      <span
        className={classNames('texture', styles.mark, styles[hotspotData.type])}
        data-custom-cursor-state={
          hotspotData.type === 'share' ? CustomCursorState.Pointer : CustomCursorState.Action
        }
        data-custom-cursor-text={hotspotData.type === 'share' ? '' : '<i>See More</i>'}
      />

      {hotspotData.isPostcard && (
        <span
          className={styles.copyPostcard}
          data-custom-cursor-state={
            hotspotData.type === 'share' ? CustomCursorState.Pointer : CustomCursorState.Action
          }
          data-custom-cursor-text={''}
        >
          SHARE A<br />. POSTCARD
        </span>
      )}
    </div>
  );
};

export { HotspotMarker };
