import throttle from 'lodash/throttle';
import React, {useCallback,useEffect, useState} from 'react';
import {Route, useHistory, useLocation} from 'react-router-dom';

import {RoutePaths} from '../../../data/enum/RoutePaths';
import {nyData} from '../../../data/nyMapData';
// import {handleMapViewAnalytics} from '../../../utils/analyticsWrapper';
import {LocationSelector} from './LocationSelector/LocationSelector';
import {MapInformation} from './MapInformation/MapInformation';
import {NYMap} from './NYMap';
import styles from './NYMapView.module.scss';

const locationItems = nyData.map((location) => ({
  name: location.name,
  cleanName: location.cleanName,
  shortName: location.shortName,
  shortNameClass: location.shortNameClass,
  description: location.description,
  href: RoutePaths.METAVERSEMIX + location.path,
}));

const NYMapView: React.FC = () => {
  
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  const {location, push} = useHistory();
  const {state} = useLocation<{ noTransition: boolean }>();
  
  const [activeRouteIndex, setActiveRouteIndex] = useState(
    locationItems.findIndex((route) => route.href === location.pathname),
  );
  
  const [showPostcardMaker, setPostcardMakerVisibility] = useState(false);
  const [showHotspotModal, setHotspotModalVisibility] = useState(false);
  
  const getActiveIndex = useCallback(() => {
    const {pathname} = location;
    const splittedPathName = pathname.split(RoutePaths.METAVERSEMIX)[1];
    return nyData.findIndex((location) => location.path === splittedPathName);
  }, [location]);
  
  const updateLocation = (indexOffset: number) => {
    if (isTransitioning || showPostcardMaker || showHotspotModal) return;
    setIsTransitioning(true);
    const currentRouteIndex = getActiveIndex();
    const newRouteIndex = (currentRouteIndex + indexOffset + nyData.length) % nyData.length;
    push(RoutePaths.METAVERSEMIX + nyData[newRouteIndex].path);
    setActiveRouteIndex(newRouteIndex);
  };
  
  const setLocation = (index: number) => {
    if (isTransitioning || index === activeRouteIndex) return;
    setIsTransitioning(true);
    push(RoutePaths.METAVERSEMIX + nyData[index].path);
    setActiveRouteIndex(index);
  };
  
  const handleWheelScroll = (event: React.WheelEvent) => {
    event.persist();
    const _handleWheelScroll = throttle(() => {
      if (Math.abs(event.deltaY) >= 3) {
        updateLocation(-Math.sign(event.deltaY));
      }
    }, 100);
    _handleWheelScroll();
  };
  
  const swipeHandler = (direction: 'up' | 'down') => {
    updateLocation(direction === 'up' ? +1 : -1);
  };
  const onModalClose = (path?: string) => {
    setHotspotModalVisibility(false);
  };
  useEffect(() => {
    const currentRouteIndex = getActiveIndex();
    setActiveRouteIndex(currentRouteIndex);
    //
    // if (!state || state.noTransition) {
    //   handleMapViewAnalytics(locationItems[currentRouteIndex].cleanName);
    // }
  }, [getActiveIndex, state]);
  
  return <div className={styles.mapDesktop} onWheel={handleWheelScroll}>
    {nyData.map((location) => (
      <Route exact path={RoutePaths.METAVERSEMIX + location.path} key={location.path}>
        {({match}) => (
          <NYMap
            location={location}
            isVisible={match !== null}
            isTransitioning={isTransitioning}
            endTransition={() => setIsTransitioning(false)}
            showPostcardMaker={showPostcardMaker}
            onPostcardMakerVisibilityToggle={setPostcardMakerVisibility}
            onModalOpen={() => setHotspotModalVisibility(true)}
            onModalClose={onModalClose}
            showModal={showHotspotModal}
          />
        )}
      </Route>
    ))}
    
    <LocationSelector
      isActive={true}
      activeElementIndex={activeRouteIndex}
      locationItems={locationItems}
      setLocation={setLocation}
      onNextButtonClick={() => swipeHandler('down')}
      onPreviousButtonClick={() => swipeHandler('up')}
    />
    
    <MapInformation
      isVisible={true}
      locationIndex={activeRouteIndex}
      locationName={locationItems[activeRouteIndex].name}
      locationDescription={locationItems[activeRouteIndex].description}
    />
  </div>;
};

export {NYMapView};
