import bgMap from '../../assets/images/map/bg_map_2.png';

import hotspot6Thumbnail from '../../assets/images/project/G-ROCKET/G-ROCKET.jpg';
import hotspot6ThumbnailHidden from '../../assets/images/project/G-ROCKET/G-ROCKET_white.jpg';
import hotspot5Thumbnail from '../../assets/images/project/PHK基金会/PHK基金会.jpg';
import hotspot5ThumbnailHidden from '../../assets/images/project/PHK基金会/PHK基金会_white.jpg';
import hotspot4Thumbnail from '../../assets/images/project/PHK市中心/PHK市中心.jpg';
import hotspot4ThumbnailHidden from '../../assets/images/project/PHK市中心/PHK市中心_white.jpg';
import hotspot3Thumbnail from '../../assets/images/project/吴杰庄议员办公区/吴杰庄议员办公区.jpg';
import hotspot3ThumbnailHidden from '../../assets/images/project/吴杰庄议员办公区/吴杰庄议员办公区_white.jpg';
import hotspot2Thumbnail from '../../assets/images/project/NFT-DEX/NFT-DEX.jpg';
import hotspot2ThumbnailHidden from '../../assets/images/project/NFT-DEX/NFT-DEX_white.jpg';
import hotspot1Thumbnail from '../../assets/images/project/会议中心/会议中心.jpg';
import hotspot1ThumbnailHidden from '../../assets/images/project/会议中心/会议中心_white.jpg';

import { MapLocation, MapLocationHotspot, ShareHotspot } from '../../types/nyMapTypes';

const shareHotspot: ShareHotspot = {
    markerPositionDesktop: {
        x: '50%',
        y: '68%',
    },
    markerPositionMobile: {
        x: '15%',
        y: '23%',
    },
    type: 'share',
    isPostcard: true,
};

const hotspot1: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '15%',
        y: '60%',
    },
    markerPositionMobile: {
        x: '20%',
        y: '42%',
    },
    type: 'DEVELOPED',
    title: `<span>党建馆</span>`,
    cleanTitle: '党建馆',
    description: `元宇宙党建馆是一种新兴的信息化平台，是党建工作与元宇宙技术相结合的产物。它是指在党组织内部建设一个元宇宙虚拟园区，将党组织的各项工作和信息集中展示，为党员干部和广大群众提供学习、交流、互动的平台。`,
    thumbnail: {
        src: hotspot1ThumbnailHidden,
        alt: 'alt',
        positionX: 'right',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot1ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot1Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
    url: 'https://space.metafactoryhub.io/#/world/people',
};

const hotspot2: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '46%',
        y: '42%',
    },
    markerPositionMobile: {
        x: '63%',
        y: '52%',
    },
    type: 'DEVELOPING',
    title: `<span>NBridge</span>`,
    cleanTitle: 'NFT-DEX',
    description: `NBridge是一个专属于NFT的交易市场，以BRIDGE桥梁为寓意，表达出WEB2到WEB3的桥梁，传统市场进入WEB3未来世界的桥梁`,
    thumbnail: {
        src: hotspot2ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'top',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot2ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot2Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
};

const hotspot3: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '60%',
        y: '45%',
    },
    markerPositionMobile: {
        x: '73%',
        y: '36%',
    },
    type: 'DEVELOPED',
    title: `<span>吴杰庄议员办公区</span>`,
    cleanTitle: '吴杰庄议员办公区',
    description: `欢迎来到港区全国政协委员、高锋集团董事局主席、香港特区立法会议员吴杰庄的办公区。吴杰庄议员曾获得香港生产促进局"香港十大杰出数码青年"称号。随着香港虚拟资产发展政策宣言发布提供的机遇，吴杰庄共同参与创立的G-Rocket创业加速器也将帮助更多Web3企业在香港取得更好的发展，一起期待中国web3的未来。`,
    thumbnail: {
        src: hotspot3ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot3ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot3Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
    closeButtonBlack: false,
    url: 'https://space.metafactoryhub.io/#/world/johnnyoffice',
};

const hotspot4: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '73%',
        y: '55%',
    },
    markerPositionMobile: {
        x: '50%',
        y: '40%',
    },
    type: 'DEVELOPED',
    title: `<span>PHK市中心</span>`,
    cleanTitle: 'PHK市中心',
    description: `拥有超过100家商店和博物馆，这里有各种各样的数字商店、数字画廊、数字博物馆等，让您可以尽情探索和购物。我们还提供了全球各地的虚拟旅游体验，让您可以身临其境地感受不同地域的文化和风情。在这里，您可以与来自世界各地的用户互动、交流、合作，共同探索元宇宙的魅力！`,
    thumbnail: {
        src: hotspot4ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot4ThumbnailHidden,
                alt: 'East Side',
            },
        ],
        hiddenContent: {
            src: hotspot4Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
    url: 'https://space.metafactoryhub.io/#/world/city',
};

const hotspot5: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '85%',
        y: '53%',
    },
    markerPositionMobile: {
        x: '50%',
        y: '38%',
    },
    type: 'PLANNING',
    title: `<span>Web3 Hub基金</span>`,
    cleanTitle: 'Web3 Hub基金',
    description: `香港设立Web3Hub基金，支持更多海外Web3.0企业落户香港。身兼创业加速器G-Rocket共同创办人的吴杰庄说，Web3Hub基金非常重要，现在很多创新行业的创业者仍在选择究竟在新加坡还是香港发展，相信通过基金和一些加速计划，可以吸引不少拥有技术的团队来港发展。他相信，香港可以通过有技术的项目，结合香港金融的传统优势，成为亚洲区最重要的Web3.0中心，也有可能成为世界上最领先的中心。`,
    thumbnail: {
        src: hotspot5ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'top',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot5ThumbnailHidden,
                alt: 'East Side',
            },
        ],
        hiddenContent: {
            src: hotspot5Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
};

const hotspot6: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '35%',
        y: '48%',
    },
    markerPositionMobile: {
        x: '50%',
        y: '38%',
    },
    type: 'PLANNING',
    title: `<span>G-ROCKET</span>`,
    cleanTitle: 'G-ROCKET',
    description: `如果你关注香港经济的发展，那你一定不会错过G-ROCKET。G-ROCKET 是立足大中华地区，面向国际的创业加速器。 随着国家提倡【大众创业，万众创新】，越来越多国内，港澳台以至海外的优质创业家开拓中国市场。 G-ROCKET 团队综合政府政策、线下配套、人才顾问网络、企业及融资对接及科技平台，协助优质和具竞争力的海內外的高科技項目包括人工智能、大数据、区块链、机器人、金融科技、医疗科技等领域，发展大中华市场。G-Rocket目标是在未来3年内帮助1000家Web3的项目在香港落地，目前已有350个项目报名了解加速器的新项目Web3hub，这些企业来自全球各地。相信在G-ROCKET的加持下，香港web3一定会开创新局面，实现新飞跃。`,
    thumbnail: {
        src: hotspot6ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'top',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot6ThumbnailHidden,
                alt: 'East Side',
            },
        ],
        hiddenContent: {
            src: hotspot6Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
};


const midtownLocationData: MapLocation = {
    bg: bgMap,
    bgMobile: '',
    path: '/midtown',
    name: 'HONGKONG',
    cleanName: 'Midtown',
    shortName: 'HONGKONG',
    shortNameClass: 'Midtown',
    description: `这里是香港元宇宙中心，连接着两个岛屿，重要会议将在此召开.`,
    backgroundImagePath: '../',
    hotspots: {
        mobile: [hotspot1, hotspot2, hotspot3, hotspot4, hotspot5, hotspot6],
        desktop: [hotspot1, hotspot2, hotspot3, hotspot4, hotspot5, hotspot6],
    },
    shareHotspot,
};

export { midtownLocationData };
