// Desktop
// import dots from '../../assets/images/map/dots.png';
// import navigationDiscover from '../../assets/images/navigation/discover.jpg';
// import navigationEpisodes from '../../assets/images/navigation/episodes.jpg';
// import navigationExtras from '../../assets/images/navigation/extras.jpg';
// import navigationHomepage from '../../assets/images/navigation/homepage.jpg';
// import skullIcon from '../../assets/images/map/skull.png';
import textureGrunge from '../../assets/images/grunge.png';
import textureInk from '../../assets/images/ink-transition3.jpg';
import mapBackgroundLocation from '../../assets/images/map/bg_location.png';
import mapBackground1 from '../../assets/images/map/bg_map_1.png';
// import mapBackground2 from '../../assets/images/map/bg_map_2.png';
// import mapBackground3 from '../../assets/images/map/bg_map_3.png';
import mapBackgroundHotspotModal from '../../assets/images/map/bg_modal_hotspot.jpg';
import mapCompass from '../../assets/images/map/compass.png';
// import mapPlayIcon from '../../assets/images/map/play_icon.png';
import mapScrollIndicator from '../../assets/images/map/scroll_ind.png';
import defaultAvatar from '../../assets/images/market/default.png';
// Postcard
// import postcardFull from '../../assets/images/postcards/postcard1_full.jpg';
import texture from '../../assets/images/texture.jpg';


const imagesToLoadDesktop = [
    // pastBackground,
    // pastBackgroundHidden,
    // nowBackground,
    // nowBackgroundHidden,
    // welcomeBackgroundHidden,
    mapBackgroundLocation,
    mapBackground1,
    // mapBackground2,
    // mapBackground3,
    mapBackgroundHotspotModal,
    mapCompass,
    // mapPlayIcon,
    mapScrollIndicator,
    // mapStamp,
    // navigationDiscover,
    // navigationEpisodes,
    // navigationExtras,
    // navigationHomepage,
    textureGrunge,
    textureInk,
    texture,
    defaultAvatar,
];

const imagesToLoadMobile = [''];

export { imagesToLoadDesktop, imagesToLoadMobile };
