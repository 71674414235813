import './assets/style/main.scss';
import './index.scss';

import bowser from 'bowser';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/App';
//@ts-ignore
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');
const bowserData = bowser.getParser(window.navigator.userAgent);
if (rootElement) {
  rootElement.setAttribute('data-browser', bowserData.getBrowserName());
  rootElement.setAttribute('data-device', bowserData.getPlatformType());
  rootElement.style.height = `${window.innerHeight}px`;
  window.addEventListener('resize', () => {
    //@ts-ignore
    rootElement.style.height = `${window.innerHeight}px`;
  });
}
ReactDOM.render(<App/>, rootElement);
serviceWorker.unregister();
export {bowserData};
