import classNames from 'classnames';
import { FunctionComponent} from 'react';
import styles from './BackgroundLines.module.scss';

export const BackgroundLines: FunctionComponent = () => {
  return (
    <div className={classNames(styles.backgroundLines)}>
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
      <span className={styles.line} />
    </div>
  );
};
