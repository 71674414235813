import bgMap from '../../assets/images/map/bg_map_3.png';

import hotspot6ThumbnailHidden from '../../assets/images/project/AITT/AITT_white.jpg';
import hotspot6Thumbnail from '../../assets/images/project/AITT/AITT.jpg';
import hotspot5ThumbnailHidden from '../../assets/images/project/根据地LIVEHOUSE/根据地LIVEHOUSE_white.jpg';
import hotspot5Thumbnail from '../../assets/images/project/根据地LIVEHOUSE/根据地LIVEHOUSE.jpg';
import hotspot4ThumbnailHidden from '../../assets/images/project/MineFI/MineFI_white.jpg';
import hotspot4Thumbnail from '../../assets/images/project/MineFI/MineFI.jpg';
import hotspot3ThumbnailHidden from '../../assets/images/project/泰坦网络/泰坦网络_white.jpg';
import hotspot3Thumbnail from '../../assets/images/project/泰坦网络/泰坦网络.jpg';
import hotspot2ThumbnailHidden from '../../assets/images/project/NewWebGroup总部/NewWebGroup总部_white.jpg';
import hotspot2Thumbnail from '../../assets/images/project/NewWebGroup总部/NewWebGroup总部.jpg';
import hotspot1Thumbnail from '../../assets/images/project/FileCoin总部/FileCoin总部.jpg';
import hotspot1ThumbnailHidden from '../../assets/images/project/FileCoin总部/FileCoin总部_white.jpg';
import { MapLocation, MapLocationHotspot, ShareHotspot } from '../../types/nyMapTypes';

const shareHotspot: ShareHotspot = {
    markerPositionDesktop: {
        x: '25%',
        y: '23%',
    },
    markerPositionMobile: {
        x: '30%',
        y: '30%',
    },
    type: 'share',
    isPostcard: true,
};

const hotspot1: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '16%',
        y: '60%',
    },
    markerPositionMobile: {
        x: '70%',
        y: '20%',
    },
    type: 'PLANNING',
    title: '<span>FILECOIN 总部</span>',
    cleanTitle: 'FILECOIN 总部',
    description: '我们很高兴您来到FILECOIN元宇宙总部，是一个集文件存储、共享和交换于一体的数字世界。在这里，用户可以使用FILECOIN数字货币购买高效、安全、低成本的文件存储和共享服务。同时，FILECOIN总部还为用户提供了一个充满创新和想象力的数字世界，用户可以在其中尽情发挥自己的创造力，创造出无限可能。',
    thumbnail: {
        src: hotspot1ThumbnailHidden,
        alt: 'alt',
        positionX: 'right',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot1ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot1Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
};


const hotspot2: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '48%',
        y: '42%',
    },
    markerPositionMobile: {
        x: '85%',
        y: '44%',
    },
    type: 'DEVELOPED',
    title: '<span>Kabosu</span>',
    cleanTitle: 'Kabosu',
    description: `Kabosu大概是世界上最出名的柴犬，它是表情包“doge”（“狗头”“神烦狗”）的原型，这里是喜欢Kabosu的用户建立的Kabosu永生元宇宙空间，希望人们对Kabosu的记忆永远保留在这里`,
    thumbnail: {
        src: hotspot2ThumbnailHidden,
        alt: 'alt',
        positionX: 'right',
        positionY: 'top',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot2ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot2Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
    url:"https://space.metafactoryhub.io/#/world/Kabosu"
};

const hotspot3: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '65%',
        y: '52%',
    },
    markerPositionMobile: {
        x: '85%',
        y: '44%',
    },
    type: 'PLANNING',
    title: '<span>泰坦网络</span>',
    cleanTitle: '泰坦网络',
    description: `泰坦网络是一个去中心化的计算机网络，旨在提供高效、安全、可靠、可扩展的计算和存储服务。泰坦网络的基础设施由一系列分布式节点组成，这些节点可以相互通信和协作，实现计算和存储任务的分布式处理。泰坦网络采用了先进的区块链技术和智能合约技术，确保了网络的安全和可靠性。同时，泰坦网络还为开发者和企业提供了一个全新的开发和应用场景，让他们可以在其中尽情发挥创造力和商业智慧。`,
    thumbnail: {
        src: hotspot3ThumbnailHidden,
        alt: 'alt',
        positionX: 'right',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot3ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot3Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
};

const hotspot4: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '90%',
        y: '42%',
    },
    markerPositionMobile: {
        x: '85%',
        y: '44%',
    },
    type: 'DEVELOPED',
    title: '<span>MineFI</span>',
    cleanTitle: 'MineFI',
    description: `MineFI矿区是元宇宙中最具活力和潜力的数字资产矿区之一。在MineFI矿区，用户可以通过挖掘、交易和投资等多种方式获取MineFI
数字资产，并且可以将MineFI数字资产用于购买元宇宙中的虚拟商品和服务。同时，MineFI矿区也为数字资产开发者和投资者提供了一个全新的开发和投资领域，让他们可以在其中尽情发挥创造力和投资智慧。我们很高兴有你和我们在一起，并希望你有一个很棒的体验。`,
    thumbnail: {
        src: hotspot4ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot4ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot4Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
    url: 'https://space.metafactoryhub.io/#/world/minefi',
};

const hotspot5: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '50%',
        y: '60%',
    },
    markerPositionMobile: {
        x: '85%',
        y: '44%',
    },
    type: 'DEVELOPING',
    title: '<span>根据地LIVEHOUSE</span>',
    cleanTitle: '根据地LIVEHOUSE',
    description: `全球首个FVM实体音乐项目NFT，在深圳根据地LIVEHOUSE酒吧 (上步店) 即将重新装修新开业之际，贝多音乐和根据地LIVEHOUSE酒吧联合推出全球第一个FVM实体音乐项目NFT (在FILECOIN FVM上发布的NFT)`,
    thumbnail: {
        src: hotspot5ThumbnailHidden,
        alt: 'alt',
        positionX: 'left',
        positionY: 'bottom',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot5ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot5Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
    url: 'https://bdnft.com/',

};

const hotspot6: MapLocationHotspot = {
    markerPositionDesktop: {
        x: '28%',
        y: '50%',
    },
    markerPositionMobile: {
        x: '70%',
        y: '20%',
    },
    type: 'DEVELOPING',
    title: '<span>AIthletic</span>',
    cleanTitle: 'AIthletic',
    description: 'AIthletic是一个基于FILECOIN去中心化可信存储的生物体AI训练平台，采用自主研发的视频索引搜索算法，实现快速检索和调用AI训练后的数据集。该平台将自动化机器学习（ML）的模型存储和增强无缝整合，确保在数据采集、存储、标注和流通过程中的可信性和安全性。作为一款创新型Web3应用平台，AITT提供了丰富的激励机制协议，旨在提高数据标注员的积极性和工作质量，进而提升数据标注的效率和质量。通过利用Filecoin的去中心化存储技术，AITT实现了标注数据的安全存储和共享，从而提高了数据的可信度和安全性。',
    thumbnail: {
        src: hotspot6ThumbnailHidden,
        alt: 'alt',
        positionX: 'right',
        positionY: 'top',
    },
    mediaContent: {
        visibleContent: [
            {
                src: hotspot6ThumbnailHidden,
                alt: 'landscape_1',
            },
        ],
        hiddenContent: {
            src: hotspot6Thumbnail,
            alt: 'landscape_2',
        },
        isVideo: false,
    },
};


const uptownLocationData: MapLocation = {
    bg: bgMap,
    bgMobile: '',
    path: '/uptown',
    name: 'BRIDGE',
    cleanName: 'Uptown',
    shortName: 'BRIDGE',
    shortNameClass: 'Uptown',
    description: `这里聚集着与WEB3生态相关的企业，聚沙成塔，势必会掀起新一轮风暴。`,
    backgroundImagePath: '../',
    hotspots: {
        mobile: [hotspot1, hotspot2, /*hotspot3,*/ /*hotspot4, */ /*hotspot5,*/ hotspot6],
        desktop: [hotspot1, hotspot2, /*hotspot3,*//* hotspot4,*/ /*hotspot5,*/ hotspot6],
    },
    shareHotspot,
};

export { uptownLocationData };
