import classNames from 'classnames';
import gsap from 'gsap';
import React, {useEffect, useRef} from 'react';
import {Transition} from 'react-transition-group';

// @ts-ignore
import {SplitText} from '../../../../vendor/SplitText.js';
import styles from './MapInformation.module.scss';

interface MapInformationProps {
  locationIndex: number;
  locationName: string;
  locationDescription: string;
  isVisible: boolean;
}

const MapInformation: React.FC<MapInformationProps> = ({
                                                         locationIndex,
                                                         locationName,
                                                         locationDescription,
                                                         isVisible,
                                                       }) => {
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const locationNameRef = useRef<HTMLHeadingElement>(null);
  const locationCaptionRef = useRef<HTMLSpanElement>(null);
  const yearRef = useRef<HTMLSpanElement>(null);
  const locationDescRef = useRef<HTMLParagraphElement>(null);
  const activeIndexRef = useRef<HTMLSpanElement>(null);

  const initialState = (node: HTMLElement) => {
    gsap.set(node, {autoAlpha: 0});
  };

  const transitionElement = (node: HTMLElement, done: () => void) => {
    gsap.set(node, {
      autoAlpha: 1,
      onComplete: done,
    });
  };

  useEffect(() => {
    if (!locationNameRef.current) return;

    gsap.fromTo(
      [locationNameRef.current, locationDescRef.current, activeIndexRef.current],
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 1.6,
      },
    );
  }, [locationName]);

  useEffect(() => {
    if (!locationNameRef.current) return;

    const mainTitle = new SplitText(mainTitleRef.current, {
      type: 'chars',
    });
    const locationName = new SplitText(locationNameRef.current, {
      type: 'chars',
    });
    const locationCaption = new SplitText(locationCaptionRef.current, {
      type: 'chars',
    });
    const year = new SplitText(yearRef.current, {
      type: 'chars',
    });
    const desc = new SplitText(locationDescRef.current, {
      type: 'lines',
    });
    const activeIndex = new SplitText(activeIndexRef.current, {
      type: 'chars',
    });

    gsap.fromTo(
      desc.lines,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 1.7,
        delay: 2,
        stagger: 0.1,
      },
    );

    gsap.fromTo(
      [locationCaption.chars, activeIndex.chars, locationName.chars],
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 1,
        delay: 1,
        stagger: 0.05,
      },
    );

    gsap.fromTo(
      mainTitle.chars,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 1.7,
        delay: 1,
        stagger: 0.1,
      },
    );

    gsap.fromTo(
      year.chars,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.3,
        delay: 1.2,
        stagger: 0.05,
      },
    );
  }, [isVisible]);

  return (
    <Transition onEnter={initialState} in={isVisible} addEndListener={transitionElement}>
      <div className={classNames(styles.mapInformation, 'texture')}>
        <div className={styles.mainTitle}>
          <h2 ref={mainTitleRef} className="texture">
            MetaFactory
          </h2>
          {/*<span ref={yearRef} className={styles.year}>*/}
          {/*  2069*/}
          {/*</span>*/}
        </div>

        <div className={styles.location}>
          <div className={styles.title}>
            <span>
              <span ref={locationCaptionRef}>NEIGHBORHOOD</span>
              <span ref={activeIndexRef} className={styles.number}>{` No.${
                locationIndex + 1
              }`}</span>
            </span>
            <h3 ref={locationNameRef} dangerouslySetInnerHTML={{__html: locationName}}/>
          </div>

          <div className={styles.descWrapper}>
            <div ref={locationDescRef}>
              <p>{locationDescription}</p>
            </div>
          </div>
        </div>

        <div className={styles.references}>
          <span className={styles.caption}>
            <span>References</span>
          </span>
          <ul>
            <li className={styles.DEVELOPED}>
              <span>DEVELOPED</span>
            </li>
            <li className={styles.DEVELOPING}>
              <span>DEVELOPING</span>
            </li>
            <li className={styles.PLANNING}>
              <span>PLANNING</span>
            </li>
          </ul>
        </div>
      </div>
    </Transition>
  );
};

export {MapInformation};

