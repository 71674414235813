import React, { useEffect, useState } from 'react';

import { Routes } from '../../routes/Routes';
import { CustomCursor } from '../CustomCursor/CustomCursor';
import { Loader } from '../Loader/Loader';
import { Noise } from '../Noise/Noise';

const App = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState(true);
    
    const onComplete = () => {
        setIsLoading(false);
    };
    useEffect(() => {
        window.history.replaceState(null, '');
    }, []);
    return (
        <div>
            <Noise />
            {isLoading ? <Loader onComplete={onComplete} /> : <Routes />}
            <CustomCursor />
        </div>
    );
};

export default App;
