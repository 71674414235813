import classNames from 'classnames';
import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// import inkTransition from '../../../../assets/images/ink-transition3.jpg';
// import marketBackground from '../../../../assets/images/map/map_mask.png';
// import {transitionWithShader} from '../../../../utils/transitionWithShader';
// @ts-ignore
import { SplitText } from '../../../../vendor/SplitText.js';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';
import { HotspotImageCarousel } from '../HotspotImageCarousel/HotspotImageCarousel';
import { ImageComparer } from '../ImageComparer/ImageComparer';
import { Modal } from '../Modal/Modal';
import styles from './HotspotModalDesktop.module.scss';
import enterImage from '../../../../assets/images/enterbutton.png';

export interface HotspotModalProps {
    isVisible: boolean;
    currentHotspotCopy: number;
    totalHotspotsCopy: number;
    hotspotData: any;
    previousHotspotClick: () => void;
    nextHotspotClick: () => void;
    closeClick: (path?: string) => void;
    openLinkClick?: () => void;
    currentLocation: string;
}

const HotspotModal: React.FC<HotspotModalProps> = ({
                                                       isVisible,
                                                       currentHotspotCopy,
                                                       totalHotspotsCopy,
                                                       hotspotData,
                                                       previousHotspotClick,
                                                       nextHotspotClick,
                                                       closeClick,
                                                       openLinkClick,
                                                       // currentLocation,
                                                   }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const mediaContentRef = useRef<HTMLDivElement>(null);
    const pageWrapperRef = useRef<HTMLSpanElement>(null);
    const titleRef = useRef<HTMLSpanElement>(null);
    const storyTypeRef = useRef<HTMLSpanElement>(null);
    const storyTypeRef2 = useRef<HTMLSpanElement>(null);
    const descriptionRef = useRef<HTMLDivElement>(null);
    const descriptionRef2 = useRef<HTMLDivElement>(null);
    const { push, location } = useHistory();

    gsap.defaults({ ease: 'expo.inOut' });

    const _previousHotspotClick = () => {
        gsap.to([titleRef.current, storyTypeRef.current, storyTypeRef2.current, descriptionRef.current, descriptionRef2.current], {
            autoAlpha: 0,
            duration: 0.5,
            onComplete: () => {
                previousHotspotClick();
                handleContentTransitionIn();
            },
        });
    };

    const _nextHotspotClick = () => {
        gsap.to([titleRef.current, storyTypeRef.current, storyTypeRef2.current, descriptionRef.current, descriptionRef2.current], {
            autoAlpha: 0,
            duration: 0.5,
            onComplete: () => {
                nextHotspotClick();
                handleContentTransitionIn();
            },
        });
    };

    // modal
    useEffect(() => {
        if (!mediaContentRef.current) return;

        push(location.pathname, { hiddenHeader: isVisible });

        const wordsDescription = new SplitText(descriptionRef.current, { type: 'words, chars' });
        const wordsDescription2 = new SplitText(descriptionRef2.current, { type: 'words, chars' });
        const wordsTitle = new SplitText(titleRef.current, { type: 'words, chars' });

        gsap.fromTo(
            [pageWrapperRef.current, titleRef.current, storyTypeRef.current, storyTypeRef2.current],
            {
                autoAlpha: isVisible ? 0 : 1,
            },
            {
                autoAlpha: isVisible ? 1 : 0,
                duration: 1,
                delay: isVisible ? 0.5 : 0,
            },
        );

        isVisible && gsap.set(wordsTitle.chars, { autoAlpha: 0 });
        gsap.to(wordsTitle.chars, {
            autoAlpha: 1,
            duration: 1.2,
            stagger: 0.05,
            delay: 0.5,
        });

        isVisible && gsap.set(descriptionRef.current, { autoAlpha: 0 });
        gsap.to(descriptionRef.current, {
            autoAlpha: 1,
            duration: 1.2,
            delay: 0.5,
        });

        isVisible && gsap.set(descriptionRef2.current, { autoAlpha: 0 });
        gsap.to(descriptionRef2.current, {
            autoAlpha: 1,
            duration: 1.2,
            delay: 0.5,
        });

        const lastCharacters = wordsDescription.chars.slice(wordsDescription.chars.length - 10);
        isVisible && gsap.set(lastCharacters, { autoAlpha: 0 });
        gsap.set(lastCharacters, {
            autoAlpha: 1,
            stagger: 0.2,
            delay: 0.85,
        });
        const lastCharacters2 = wordsDescription2.chars.slice(wordsDescription2.chars.length - 10);
        isVisible && gsap.set(lastCharacters2, { autoAlpha: 0 });
        gsap.set(lastCharacters2, {
            autoAlpha: 1,
            stagger: 0.2,
            delay: 0.85,
        });
    }, [isVisible, location.pathname, push]);

    const handleContentTransitionIn = () => {
        if (!mediaContentRef.current) return;

        gsap.to(
            [pageWrapperRef.current, titleRef.current, storyTypeRef.current, storyTypeRef2.current, descriptionRef.current, descriptionRef2.current],
            {
                y: 0,
                autoAlpha: 1,
                duration: 0.4,
            },
        );
    };
    // const pushToMarket = (path: string) => {
    //   closeClick(path);
    //   transitionWithShader(
    //     [marketBackground, inkTransition],
    //     () => {
    //       push(path, {noTransition: true});
    //       gsap.set(wrapperRef.current, {autoAlpha: 0});
    //     },
    //     () => {
    //       console.log('-> onComplete');
    //     },
    //     10,
    //   );
    // };
    useEffect(() => {
        handleContentTransitionIn();
    }, []);

    // useEffect(() => {
    //   if (isVisible) {
    //     handleHotspotDetailsViewAnalytics(currentLocation, hotspotData.cleanTitle);
    //   }
    // }, [hotspotData, isVisible, currentLocation]);

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <Modal isVisible={isVisible} closeClick={closeClick}>
            <div className={styles.contentWrapper} ref={wrapperRef}>
                <div className={styles.mediaContent} ref={mediaContentRef}>
                    {hotspotData.mediaContent.hiddenContent ? (
                        // hotspotData.mediaContent.isVideo ? (
                        //   <>
                        //     <span className={styles.playButton}/>
                        //     <VideoPlayer
                        //       src={hotspotData.mediaContent.visibleContent[0].src}
                        //       visiblePosterSrc={hotspotData.mediaContent.hiddenContent.src}
                        //     />
                        //   </>
                        // ) : (
                        <ImageComparer
                            visibleContent={hotspotData.mediaContent.visibleContent[0]}
                            hiddenContent={hotspotData.mediaContent.hiddenContent}
                        />
                        // )
                    ) : (
                        <HotspotImageCarousel images={hotspotData.mediaContent.visibleContent} />
                    )}
                </div>

                <div className={classNames('texture', styles.copyWrapper)}>
                    <div className={styles.titleWrapper}>
            <span className={styles.pageWrapper} ref={pageWrapperRef}>
              <span>{currentHotspotCopy.toString().padStart(2, '0')}</span>
              <span>{totalHotspotsCopy.toString().padStart(2, '0')}</span>
            </span>
                        <span
                            className={styles.title}
                            ref={titleRef}
                            dangerouslySetInnerHTML={{ __html: hotspotData.title }}
                        />
                    </div>

                    <div className={styles.descriptionWrapper}>
                        <div
                            className={classNames(
                                styles.captionWrapper,
                                hotspotData.isDarkPin && styles.darkPinCaption,
                            )}
                        >
              <span className={styles[hotspotData.type]} ref={storyTypeRef}>
                <img src={enterImage} onClick={openLinkClick} />

                  {/*{hotspotData.type}*/}
              </span>
                        </div>

                        <div className={styles.description}>
                            <p
                                ref={descriptionRef}
                                dangerouslySetInnerHTML={{ __html: hotspotData.description }}
                            />
                        </div>
                    </div>
                </div>

                <div className={classNames('texture', styles.buttonsWrapper)}>
                    <div className={styles.buttonWrapper}>
                        <button
                            onClick={_previousHotspotClick}
                            data-custom-cursor-state={CustomCursorState.Pointer}
                        >
                            <span>Previous location</span>
                        </button>
                    </div>

                    <div className={styles.buttonWrapper}>
                        <button
                            onClick={_nextHotspotClick}
                            data-custom-cursor-state={CustomCursorState.Pointer}
                        >
                            <span>Next location</span>
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export { HotspotModal };
