import classNames from 'classnames';
import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable';
import React, { useEffect, useRef, useState } from 'react';

import { useDeviceState } from '../../../../hooks/useDeviceState';
import { MediaContent } from '../../../../types/nyMapTypes';
// @ts-ignore
import { InertiaPlugin } from '../../../../vendor/InertiaPlugin.js';
import { CustomCursorState } from '../../../CustomCursor/CustomCursor';
import styles from './HotspotImageCarousel.module.scss';

interface HotspotImageCarouselProps {
  images: MediaContent[];
}

const HotspotImageCarousel: React.FC<HotspotImageCarouselProps> = ({ images }) => {
  const { isTouch } = useDeviceState();

  const draggableWrapper = useRef<HTMLDivElement>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    gsap.registerPlugin(Draggable);
    gsap.registerPlugin(InertiaPlugin);
  }, []);

  useEffect(() => {
    if (!draggableWrapper.current || !isTouch) {
      return;
    }

    const itemWidth = draggableWrapper.current.getBoundingClientRect().width;

    const xSnap = images.map((image, index) => {
      return Math.round(-index * itemWidth);
    });

    Draggable.create(draggableWrapper.current, {
      type: 'x',
      inertia: true,
      snap: { x: xSnap },
      onThrowComplete: function () {
        setActiveSlideIndex(xSnap.findIndex((snapValue) => snapValue === Math.round(this.x)));
      },
    });
  }, [images, isTouch]);

  const slideTo = (index: number) => {
    if (!draggableWrapper.current) {
      return;
    }

    setActiveSlideIndex(index);
    const itemWidth = draggableWrapper.current.getBoundingClientRect().width;

    gsap.to(draggableWrapper.current, {
      x: -index * itemWidth,
    });
  };

  return images.length === 1 ? (
    <img src={images[0].src} alt={images[0].alt}></img>
  ) : (
    <div className={styles.carouselWrapper}>
      <div className={styles.draggableWrapper} ref={draggableWrapper}>
        {images.map((image, index) => (
          <img src={image.src} alt={image.alt} key={index}></img>
        ))}
      </div>
      {!isTouch && (
        <>
          <button
            className={classNames(
              styles.navigationButton,
              styles.left,
              activeSlideIndex === 0 && styles.hide,
            )}
            onClick={() => slideTo(activeSlideIndex - 1)}
            data-custom-cursor-text="<i>Previous</i>"
            data-custom-cursor-state={CustomCursorState.Action}
          ></button>
          <button
            className={classNames(
              styles.navigationButton,
              styles.right,
              activeSlideIndex === images.length - 1 && styles.hide,
            )}
            onClick={() => slideTo(activeSlideIndex + 1)}
            data-custom-cursor-text="<i>Next</i>"
            data-custom-cursor-state={CustomCursorState.Action}
          ></button>
        </>
      )}
      <div className={styles.buttonGroup}>
        {images.map((image, index) => (
          <button
            onClick={() => slideTo(index)}
            key={index}
            className={classNames(activeSlideIndex === index && styles.active)}
            data-custom-cursor-state={CustomCursorState.Pointer}
          >
            {index}
          </button>
        ))}
      </div>
    </div>
  );
};

export { HotspotImageCarousel };
