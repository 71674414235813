import classNames from 'classnames';
import gsap from 'gsap';
import React, {useEffect, useRef} from 'react';

import path from '../../../../assets/images/map/path.png';
// @ts-ignore
import {SplitText} from '../../../../vendor/SplitText.js';
import {CustomCursorState} from '../../../CustomCursor/CustomCursor';
import styles from './LocationSelectorDesktop.module.scss';

interface LocationSelectorProps {
  activeElementIndex: number;
  locationItems: Array<LocationSelectorItemProps>;
  setLocation: (index: number) => void;
  isActive: boolean;
  onNextButtonClick?: () => void;
  onPreviousButtonClick?: () => void;
}

interface LocationSelectorItemProps {
  shortName: string;
  shortNameClass: string;
  href: string;
}

const LocationSelector: React.FC<LocationSelectorProps> = (props) => {
  const {activeElementIndex, locationItems, isActive, setLocation, onNextButtonClick, onPreviousButtonClick} = props;
  const copyRef = useRef<HTMLSpanElement>(null);
  
  useEffect(() => {
    if (!copyRef.current) return;
    const copy = new SplitText(copyRef.current, {
      type: 'chars',
    });
    
    gsap.fromTo(
      copy.chars,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.3,
        delay: 0.5,
        stagger: 0.1,
      },
    );
  }, [isActive]);
  
  return (
    <div className={styles.locationSelector}>
      <div className={styles.indicator}>
        <span ref={copyRef} className={classNames('texture', styles.indicatorCopy)}>
          SCROLL UP/DOWN
          <br/>
          TO EXPLORE
        </span>
        <button
          className={classNames(styles.navButton, styles.prevButton)}
          onClick={onPreviousButtonClick}
          data-custom-cursor-state={CustomCursorState.Pointer}
        />
        <button
          className={classNames(styles.navButton, styles.nextButton)}
          onClick={onNextButtonClick}
          data-custom-cursor-state={CustomCursorState.Pointer}
        />
      </div>
      <div className={classNames('texture', styles.bgHorizontalLocation)}/>
      <div className={classNames('texture', styles.bgVerticalLocation)}/>
      {locationItems.map((locationItem, index) => {
        return (
          <div
            key={index}
            className={classNames(
              styles.itemWrapper,
              activeElementIndex === index ? styles.isActive : '',
            )}
            onClick={() => setLocation(index)}
            data-custom-cursor-state={CustomCursorState.Pointer}
          >
            <div
              className={classNames(styles.item, styles[locationItem.shortNameClass])}
              key={index}
            >
              <div className={styles.link}>
                <div className={styles.locationNameWrapper}>
                  <span dangerouslySetInnerHTML={{__html: locationItem.shortName}}/>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <img className={styles.locationPath} src={path} alt="path"/>
    </div>
  );
};

export {LocationSelector};
export type {LocationSelectorProps};
